import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "addCourse" }
const _hoisted_2 = { class: "panel-body" }

import { onMounted, ref, watch } from "vue";
import { ElMessage } from "element-plus";
import { CourseControllerService } from "../../../generated/services/CourseControllerService";
import { CourseAddDTO } from "../../../generated";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddCourseView',
  setup(__props) {

const parent = ref();
const parentList = ref();

const isUpdate = ref(false);
const avatarData = ref({
  courseName: "",
});

const courseAvatarUploadPath = ref("");
// 层级数组
const options = [
  {
    value: 1,
    label: "第一层",
  },
  {
    value: 2,
    label: "第二层",
  },
  {
    value: 3,
    label: "第三层",
  },
];
// 新建课程请求体
const form = ref({
  name: "",
  pid: 0,
  level: 0,
  description: "",
} as CourseAddDTO);

// 图片上传成功，回调函数
const handleAvatarSuccess = (response) => {
  if (response.code === 0) {
    form.value.avatar = response.data;
  } else {
    ElMessage.error("上传失败：{}", response.message);
  }
};

watch(
  () => form.value.name,
  (newValue) => {
    avatarData.value.courseName = newValue as string;
  }
);

const getParent = async () => {
  parentList.value = null;
  parent.value = null;
  const res = await CourseControllerService.getCourseByLevel(
    form.value.level as number
  );
  if (res.code === 0) {
    parentList.value = res.data;
  } else {
    ElMessage.error(res.message);
  }
};

const onSubmit = async () => {
  form.value.pid = parent.value;
  const res = await CourseControllerService.addCourse(form.value);
  if (res.code === 0) {
    ElMessage.success("添加成功");
  } else {
    ElMessage.error("添加失败：" + res.message);
  }
};
const updateCourse = async () => {
  const res = await CourseControllerService.updateProblem(form.value);
  if (res.code === 0) {
    ElMessage.success("修改成功");
  } else {
    ElMessage.error("修改失败：" + res.message);
  }
};
onMounted(() => {
  courseAvatarUploadPath.value = process.env
    .VUE_APP_COURSE_AVATAR_UPLOAD_PATH as string;
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_form, {
        model: form.value,
        "label-width": "auto",
        style: {"max-width":"600px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "名称" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: form.value.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.name) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "层级" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: form.value.level,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.level) = $event)),
                placeholder: "请选择层级"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(options, (item) => {
                    return _createVNode(_component_el_option, {
                      key: item.value,
                      label: item.label,
                      value: item.value,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (getParent()))
                    }, null, 8, ["label", "value"])
                  }), 64))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "父级" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: parent.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((parent).value = $event)),
                placeholder: "请选择父级"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parentList.value, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.value,
                      label: item.name,
                      value: item.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "描述" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: form.value.description,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.description) = $event)),
                type: "textarea"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_upload, {
            class: "upload-demo",
            drag: "",
            action: courseAvatarUploadPath.value,
            "on-success": handleAvatarSuccess,
            data: avatarData.value
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("i", { class: "el-icon-upload" }, null, -1),
              _createElementVNode("div", { class: "el-upload__text" }, [
                _createTextVNode("将图片上传至此，或"),
                _createElementVNode("em", null, "点击上传")
              ], -1)
            ])),
            _: 1
          }, 8, ["action", "data"]),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              (isUpdate.value === false)
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    type: "primary",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (onSubmit()))
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("提交 ")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_el_button, {
                    key: 1,
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (updateCourse()))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("修改")
                    ])),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ]))
}
}

})