import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "addQuestion" }
const _hoisted_2 = { class: "panel-body" }

import { onBeforeMount, onMounted, ref } from "vue";
import MdEditor from "@/components/MdEditor.vue";
import { useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ProblemControllerService } from "../../../generated/services/ProblemControllerService";
import { useUserStore } from "@/store/UserStore";
import { ProblemVO } from "../../../generated/models/ProblemVO";
import { ProblemAddDTO } from "../../../generated/models/ProblemAddDTO";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddProblemView',
  setup(__props) {

const route = useRoute();
const userStore = useUserStore();
const isUpdate = ref(false);

const fileData = ref({
  pid: "deafualt",
});
const judgeCaseUploadPath = ref("");
const form = ref({
  problemId: "",
  title: "",
  author: "",
  description: "",
  tagList: [],
  timeLimit: 0,
  memoryLimit: 0,
  stackLimit: 0,
  input: "",
  output: "",
  source: "",
  difficulty: 0,
  auth: 0,
  judgeMode: "default",
  spjCode: "",
  spjLanguage: "",
} as ProblemAddDTO);

const addQuestion = async () => {
  form.value.author = userStore.userInfo.userName;
  const result = await ProblemControllerService.addProblem(form.value);
  if (result.code === 0) {
    ElMessage.success("添加成功");
  } else if (result.code === 201) {
    ElMessageBox.confirm("没有该标签，是否创建？", "Warning", {
      confirmButtonText: "添加",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        if (Array.isArray(form.value.tagList)) {
          for (const s of form.value.tagList) {
            ProblemControllerService.addTag(s);
          }
        }
        ElMessage({
          type: "success",
          message: "添加成功",
        });
      })
      .catch(() => {
        ElMessage({
          type: "info",
          message: "取消添加",
        });
      });
  } else {
    ElMessage.error("添加失败：" + result.message);
  }
};
const updateQuestion = async () => {
  const result = await ProblemControllerService.updateProblem(form.value);
  if (result.code === 0) {
    ElMessage.success("修改成功");
  } else {
    ElMessage.error("修改失败：" + result.message);
  }
};

const onContentMdchange = (v: string) => {
  form.value.description = v;
};

const loadData = async () => {
  const id = route.query.id;
  const update = route.query.update;
  if (update) {
    isUpdate.value = true;
  }
  if (!id) {
    return;
  }
  const res = await ProblemControllerService.getProblem(Number(id));
  if (res.code === 0 && res.data !== undefined) {
    form.value = res.data as ProblemVO;
  } else {
    ElMessage.error("加载失败：" + res.message);
  }
};

onBeforeMount(() => {
  loadData();
  fileData.value.pid = form.value.problemId as string;
});
onMounted(() => {
  judgeCaseUploadPath.value = process.env
    .VUE_APP_JUDGECASE_UPLOAD_PATH as string;
});

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_tag = _resolveComponent("a-input-tag")!
  const _component_a_option = _resolveComponent("a-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_space, { style: {"justify-content":"space-between"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            field: "title",
            label: "题目"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                modelValue: form.value.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.title) = $event)),
                placeholder: "请输入题目标题"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "problemId",
            label: "自定义ID"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                modelValue: form.value.problemId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.problemId) = $event)),
                placeholder: "请输入自定义id"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "tags",
            label: "标签"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_tag, {
                modelValue: form.value.tagList,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.tagList) = $event)),
                placeholder: "请输入题目标签"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "difficulty",
            label: "难度"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                style: { width: '100px' },
                placeholder: "请选择",
                modelValue: form.value.difficulty,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.difficulty) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_option, { value: "0" }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("简单")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_option, { value: "1" }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("中等")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_option, { value: "2" }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("困难")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        field: "content",
        label: "内容"
      }, {
        default: _withCtx(() => [
          _createVNode(MdEditor, {
            value: form.value.description,
            "handle-change": onContentMdchange
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        label: "配置",
        "content-flex": false,
        "merge-props": false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_space, {
            direction: "vertical",
            fill: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                field: "judgeConfig.timeLimit1",
                label: "时间限制"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    modelValue: form.value.timeLimit,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.timeLimit) = $event)),
                    placeholder: "时间限制"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                field: "judgeConfig.memoryLimit",
                label: "内存限制"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    modelValue: form.value.memoryLimit,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.value.memoryLimit) = $event)),
                    placeholder: "内存限制"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                field: "judgeConfig.stackLimit",
                label: "堆栈限制"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    modelValue: form.value.stackLimit,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((form.value.stackLimit) = $event)),
                    placeholder: "堆栈限制"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_upload, {
        class: "upload-demo",
        drag: "",
        headers: "POST",
        action: judgeCaseUploadPath.value,
        multiple: "",
        data: fileData.value
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createElementVNode("i", { class: "el-icon-upload" }, null, -1),
          _createElementVNode("div", { class: "el-upload__text" }, [
            _createTextVNode("将文件拖到此处，或"),
            _createElementVNode("em", null, "点击上传")
          ], -1)
        ])),
        _: 1
      }, 8, ["action", "data"]),
      (isUpdate.value === false)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            status: "success",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (addQuestion()))
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("提交 ")
            ])),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_a_button, {
            key: 1,
            status: "success",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (updateQuestion()))
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("修改 ")
            ])),
            _: 1
          }))
    ])
  ]))
}
}

})